import { ButtonAccent, ButtonPrimary } from '@lambdacurry/component-library';
import classNames from 'classnames';
import { CSSProperties, FC } from 'react';
import { SplitPanelCopy as SplitPanelCopyInterface } from './SplitPanel.types';

export interface SplitPanelCopyProps {
  className?: string;
  copy: SplitPanelCopyInterface;
  style?: CSSProperties;
}

export const SplitPanelCopy: FC<SplitPanelCopyProps> = ({ children, className, copy, style }) => {
  const { title, body, button } = copy;

  return (
    <div
      className={classNames(`split-panel__copy flex flex-col p-3 sm:p-4 md:p-6 lg:p-8 xl:p-16 w-full`, className)}
      style={style}
    >
      {children}

      <h2 className="h1 max-w-lg">{title}</h2>
      {body && <p>{body}</p>}
      {button}
    </div>
  );
};
