import {
  AmazonLogo,
  BuzzfeedLogo,
  DancingWithTheStarsLogo,
  DeltaLogo,
  DodgersLogo,
  GiantsLogo,
  GoogleLogo,
  MLBLogo,
  NetflixLogo,
  NFLLogo,
  PatriotsLogo,
  SamsungLogo,
  UpstartLogo,
  WarriorsLogo
} from '../../images';

export const companySlides = [BuzzfeedLogo, DeltaLogo, UpstartLogo, NetflixLogo, AmazonLogo, GoogleLogo, SamsungLogo];
export const sportSlides = [
  DancingWithTheStarsLogo,
  MLBLogo,
  NFLLogo,
  PatriotsLogo,
  DodgersLogo,
  GiantsLogo,
  WarriorsLogo
];
