import { ButtonAccent, ButtonPrimary } from '@lambdacurry/component-library';
import React from 'react';
import classNames from 'classnames';
import {
  Container,
  Grid,
  IconBar,
  IconBarItem,
  ImageColumn,
  Layout,
  Section,
  SplitPanel,
  SplitPanelContent,
  SplitPanelCopy,
  SplitPanelImage,
  WeeklyMenu
} from '../components';

import {
  CookIcon,
  DishIcon,
  PriceIcon,
  GreenPattern,
  WhiteTopPattern,
  NibllLogo,
  columnTrainerImgOne,
  WhiteDiamond,
  ShopIcon,
  heroPescatarianImg,
  columnPescatarianImgOne,
  columnPescatarianImgTwo,
  ctaPescatarianImgOne,
  ctaPescatarianImgTwo
} from '../images';

import backgroundPattern from '../images/patterns/background.svg';

export const PescatarianPage = () => (
  <Layout metadata={{ title: 'Home', description: 'NIBLL - Healthy meals delivered to your door.' }}>
    <SplitPanel>
      <SplitPanelContent>
        <SplitPanelCopy
          copy={{
            title: 'Incredible fish-forward meals delivered.',
            body: 'Happiness and health both start with the food we eat.',
            button: (
              <ButtonPrimary
                className="w-full md:w-auto mr-auto mt-2"
                as={buttonProps => (
                  <a {...buttonProps} href="https://www.nibll.com/#meal-plans" rel="noreferrer">
                    Get Started
                  </a>
                )}
              />
            )
          }}
        >
          <NibllLogo className="mb-5 max-w-[120px]" />
        </SplitPanelCopy>

        <GreenPattern className="w-full" />
      </SplitPanelContent>

      <SplitPanelImage src={heroPescatarianImg.default} alt="This is food!" />
    </SplitPanel>

    <Section>
      <Container>
        <Grid>
          <h2 className="col-span-12 md:col-span-5 self-center">How NIBLL ready-made meals work.</h2>

          <IconBar>
            <IconBarItem icon={<ShopIcon className="max-w-[24px] md:max-w-[40px]" />} label="No shopping" />
            <IconBarItem
              className="divider"
              icon={<CookIcon className="max-w-[24px] md:max-w-[40px]" />}
              label="No cooking"
            />
            <IconBarItem icon={<DishIcon className="max-w-[24px] md:max-w-[40px]" />} label="No dishes" />
          </IconBar>

          <ImageColumn copy="Select a meal plan" image={{ url: columnTrainerImgOne.default, alt: 'This is food!' }} />
          <ImageColumn
            copy="We cook & deliver"
            image={{ url: columnPescatarianImgOne.default, alt: 'This is food!' }}
          />
          <ImageColumn copy="Just add heat" image={{ url: columnPescatarianImgTwo.default, alt: 'This is food!' }} />
        </Grid>
      </Container>
    </Section>

    <SplitPanel>
      <SplitPanelImage src={ctaPescatarianImgOne.default} alt="This is food!" />
      <SplitPanelContent className="bg-brand-primary">
        <WhiteTopPattern className="w-full" />

        <SplitPanelCopy
          className="text-brand-background"
          copy={{
            title: 'What will you do with 10 extra hours/week?',
            body: 'Learn a language...train for a marathon...binge a new show?',
            button: (
              <ButtonAccent
                as={buttonProps => (
                  <a {...buttonProps} href="https://www.nibll.com/#meal-plans" rel="noreferrer">
                    Learn More
                  </a>
                )}
                className="!text-black w-full md:w-auto mr-auto mt-2"
              />
            )
          }}
        />

        <WhiteTopPattern className="w-full rotate-180" />
      </SplitPanelContent>
    </SplitPanel>

    <Section style={{ backgroundImage: `url(${backgroundPattern})`, backgroundSize: 'cover' }}>
      <Container>
        <div className={classNames(`border border-solid border-brand-green-500`)}>
          <div className="bg-brand-background p-3 lg:px-16 lg:py-10">
            <h2>This Week's Menu</h2>
          </div>

          <WeeklyMenu value={1} />
        </div>

        <div className="bg-brand-primary flex flex-col md:flex-row items-center justify-center">
          <WhiteDiamond className="my-4 max-w-[248px]" />

          <div className="flex items-center md:mx-3">
            <PriceIcon height="40" width="40" className="max-w-[40px]" />
            <p className="ml-2 text-white">
              Starting at <strong>$89/week</strong>
            </p>
          </div>

          <WhiteDiamond className="my-4 max-w-[248px]" />
        </div>
      </Container>
    </Section>

    <SplitPanel>
      <SplitPanelImage src={ctaPescatarianImgTwo.default} alt="This is food!" />
      <SplitPanelContent className="bg-brand-primary">
        <SplitPanelCopy
          className="text-brand-background md:bg-white-vertical-pattern bg-right bg-contain bg-no-repeat"
          copy={{
            title: 'Take your first bite on us.',
            button: (
              <ButtonAccent
                as={buttonProps => (
                  <a {...buttonProps} href="https://www.nibll.com/#meal-plans" rel="noreferrer">
                    Get Started
                  </a>
                )}
                className="!text-black w-full md:w-auto mr-auto mt-2"
              />
            )
          }}
        />
      </SplitPanelContent>
    </SplitPanel>
  </Layout>
);

export default PescatarianPage;
