export * from './icons';
export * from './logos';
export * from './patterns';

export { ReactComponent as NibllLogo } from './logo.svg';
export { ReactComponent as GreenDiamond } from './diamond-green.svg';
export { ReactComponent as WhiteDiamond } from './diamond-white.svg';

export * as nextArrow from './btn-arrow-next.png';
export * as prevArrow from './btn-arrow-prev.png';
export * as columnImgOne from './column-img-1.jpg';
export * as columnImgTwo from './column-img-2.jpg';
export * as columnImgThree from './column-img-3.jpg';
export * as ctaImgOne from './cta-img-1.jpg';
export * as ctaImgTwo from './cta-img-2.jpg';
export * as heroImg from './hero-img.jpg';
export * as modalImg from './modal.jpg';

export * as heroTrainersImg from './hero-trainers.jpg';
export * as columnTrainerImgOne from './column-trainer-1.jpg';
export * as columnTrainerImgTwo from './column-trainer-2.jpg';
export * as columnTrainerImgThree from './column-trainer-3.jpg';
export * as ctaTrainerImg from './cta-trainer-1.jpg';

export * as ctaPlantsImgOne from './cta-plants-1.jpg';
export * as ctaPlantsImgTwo from './cta-plants-2.jpg';

export * as ctaBusyImgOne from './cta-busy-1.jpg';
export * as ctaBusyImgTwo from './cta-busy-2.jpg';

export * as heroPescatarianImg from './hero-pescatarian.jpg';
export * as columnPescatarianImgOne from './column-pescatarian-1.jpg';
export * as columnPescatarianImgTwo from './column-pescatarian-2.jpg';
export * as ctaPescatarianImgOne from './cta-pescatarian-1.jpg';
export * as ctaPescatarianImgTwo from './cta-pescatarian-2.jpg';
