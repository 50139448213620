import classNames from 'classnames';
import { CSSProperties, FC } from 'react';
import { Container } from '../Container/Container';

export interface SectionProps {
  className?: string;
  style?: CSSProperties;
}

export const Section: FC<SectionProps> = ({ children, className, style }) => {
  return (
    <section className={classNames(`flex py-3 sm:py-4 md:py-6 lg:py-8 xl:py-36`, className)} style={style}>
      {children}
    </section>
  );
};
