import { FC } from 'react';
import classNames from 'classnames';

export interface SplitPanelProps {
  className?: string;
}

export const SplitPanel: FC<SplitPanelProps> = ({ children, className }) => {
  return <section className={classNames(`split-panel grid grid-cols-12 auto-rows-fr`, className)}>{children}</section>;
};
