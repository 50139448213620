import { SnackbarProvider } from '@lambdacurry/component-library/dist/lib/snackbars/SnackbarProvider';
import { FC } from 'react';
import { Footer, Header, Metadata, PartnerSlider, TestimonialSlider } from '..';
import { MetadataProps } from '../Metadata/Metadata.types';
import './layout.scss';

export interface LayoutProps {
  className?: string;
  metadata?: MetadataProps;
}

export const Layout: FC<LayoutProps> = ({ children, metadata }) => (
  <>
    <Metadata {...metadata} />

    <SnackbarProvider>
      <main>
        <Header />

        {children}

        <PartnerSlider />
        <TestimonialSlider />

        <Footer />
      </main>
    </SnackbarProvider>
  </>
);
