import { Tabs } from '@lambdacurry/component-library';
import axios from 'axios';
import classNames from 'classnames';
import { FC, useEffect, useState } from 'react';
import { MiseDish, MiseWeeklyMenu } from './WeeklyMenu.types';
import patternMenuTile from '../../images/patterns/menu-tile.svg';
import './weekly-menu.scss';

export interface WeeklyMenuProps {
  className?: string;
  value?: number;
}

export const WeeklyMenu: FC<WeeklyMenuProps> = ({ className, value = 0 }) => {
  const [menus, setMenus] = useState<MiseWeeklyMenu[]>([]);

  const filterEntrees = (dishes: MiseDish[]) => dishes.filter(dish => dish.type === '1st Entree');
  const filterSaladAndSoup = (dishes: MiseDish[]) => dishes.filter(dish => dish.type !== '1st Entree');

  const generateTabs = () =>
    menus.map((menu, index) => ({
      label: menu.type,
      render: (
        <div key={`tab-${index}`} className="grid grid-cols-12 auto-rows-fr">
          <div className="col-span-12 md:col-span-6 p-3 sm:p-4 md:p- lg:p-8 xl:p-16">
            <h6>Entrees</h6>

            <ul className="list-disc pl-4">
              {filterEntrees(menu.dishes).map((dish, index) => (
                <li key={`entree-${index}`} className="font-body text-base leading-8">
                  {dish.name}
                </li>
              ))}
            </ul>
            <h6>Salads and Soups</h6>

            <ul className="list-disc pl-4">
              {filterSaladAndSoup(menu.dishes).map((dish, index) => (
                <li key={`side-${index}`} className="font-body text-base leading-8">
                  {dish.name}
                </li>
              ))}
            </ul>
          </div>

          <div className="grid grid-cols-12 col-span-12 md:col-span-6">
            <div
              className="col-span-6"
              style={{
                backgroundImage: `url(${patternMenuTile})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover'
              }}
            />

            <div
              className="col-span-6"
              style={{
                backgroundImage: `url(${menu.image})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover'
              }}
            />

            <div
              className="col-span-6"
              style={{
                backgroundImage: `url(${menu.secondary_image})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover'
              }}
            />

            <div
              className="col-span-6"
              style={{
                backgroundImage: `url(${patternMenuTile})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover'
              }}
            />
          </div>
        </div>
      )
    }));

  useEffect(() => {
    const loadMenus = async () => {
      const weeklyMenuResponse = await axios.get(`https://admin.nibll.com/weekly-home-menu/find-for-week`);

      // TODO: Better error handling
      if (!weeklyMenuResponse.data || !weeklyMenuResponse.data.menus) return;

      setMenus(weeklyMenuResponse.data.menus);
    };

    loadMenus();
  }, []);

  return <Tabs className={classNames(`weekly-menu`, className)} tabs={generateTabs()} value={value} />;
};
