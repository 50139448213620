import { useRef } from 'react';
import Slider, { Settings } from 'react-slick';
import { slides } from './TestimonialSlider.helpers';
import { Container, Grid, Section } from '..';
import { nextArrow, prevArrow, QuoteIcon, TestimonialPattern } from '../../images';

export const TestimonialSlider = () => {
  const sliderRef = useRef(null);

  const settings: Settings = {
    arrows: false,
    autoplay: true,
    dots: false,
    infinite: true,
    slidesToScroll: 1,
    slidesToShow: 1,
    autoplaySpeed: 12000
  };

  const handleClickPrev = () => sliderRef.current.slickPrev();
  const handleClickNext = () => sliderRef.current.slickNext();

  return (
    <Section className="bg-brand-green-500 flex-col overflow-hidden !px-0 !py-14 relative">
      <TestimonialPattern className="absolute mx-auto left-0 right-0 top-0 min-h-[51px]" />

      <Container className="py-3 sm:py-4 md:py-6 lg:py-8 xl:py-36">
        <Grid>
          <div className="flex flex-col col-span-12 md:col-span-6">
            <h2>Testimonials</h2>

            <div className="mt-3">
              <button onClick={() => handleClickPrev()}>
                <img className="max-w-[40px] mr-3" src={prevArrow.default} alt="Click to go to the previous slide." />
              </button>

              <button onClick={() => handleClickNext()}>
                <img className="max-w-[40px] ml-3" src={nextArrow.default} alt="Click to go to the next slide." />
              </button>
            </div>
          </div>

          <Slider className="col-span-12 md:col-span-6" ref={sliderRef} {...settings}>
            {slides.map((slide, index) => (
              <div key={index}>
                <p>{slide.copy}</p>

                <div className="flex">
                  <QuoteIcon className="max-w-[40px]" />
                  <h6 className="mx-5 md:mx-10">{slide.citation}</h6>
                  {slide.position && <h6 className="font-normal">{slide.position}</h6>}
                </div>
              </div>
            ))}
          </Slider>
        </Grid>
      </Container>

      <TestimonialPattern className="absolute mx-auto left-0 right-0 bottom-0 min-h-[51px]" />
    </Section>
  );
};
