import { Container } from '..';
import { CustomCountdown } from '../CustomCountdown/CustomCountdown';
import './header.scss';

export const Header = () => (
  <header className="bg-brand-green-500 py-3 sm:py-4 md:py-6">
    <Container>
      <a href="https://www.nibll.com/#meal-plans" rel="noreferrer">
        <div className="flex flex-wrap items-center justify-between">
          <div className="sm:flex-1 w-full">
            <p className="font-display m-0 text-center sm:text-left text-sm">
              <strong>Get 30% off your first order using the code EATWELL</strong>
            </p>
          </div>

          <div className="flex sm:flex-1 justify-center sm:justify-end w-full">
            <CustomCountdown />
          </div>
        </div>
      </a>
    </Container>
  </header>
);
