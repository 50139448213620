import { FC } from 'react';
import './icon-bar.scss';

export interface IconBarProps {
  className?: string;
}

export const IconBar: FC<IconBarProps> = ({ children }) => (
  <div className="icon-bar bg-brand-text-100 col-span-12 md:col-span-7 flex my-3 py-3 sm:py-6">{children}</div>
);
