import classNames from 'classnames';
import { FC, ReactNode } from 'react';

export interface FooterIconProps {
  className?: string;
  href: string;
  icon: ReactNode;
}

export const FooterIcon: FC<FooterIconProps> = ({ className, href, icon }) => (
  <a className={classNames(`mx-4`, className)} href={href} rel="noreferrer" target="_blank">
    {icon}
  </a>
);
