export const slides = [
  {
    copy: 'You know, my eating habits have not changed at all with pregnancy! I also eat  healthy and balanced. The question is ... when to cook it? the schedule is tense and by the evening my strength leaves me.',
    citation: 'Rachel',
    position: 'Personal Trainer Blogger'
  },
  {
    copy: 'This is one of the best home delivery meal plan food I have ever had. I also like that you are not too shy about the flavors.  So many other places chicken out and make everything so bland.',
    citation: 'Melissa'
  },
  {
    copy: "I truly am enjoying the meals. I've never done any kind of meal plan before, but now that I'm living alone in quarantine, it's been a nice gift to myself. The variety of food balances out the endless pots of chili and stews that I'm otherwise making.",
    citation: 'Carrie'
  },
  {
    copy: "Thank you, these delivered meals are delicious! Best pre-packaged meals I think I've ever had. Much appreciated.",
    citation: 'Linda'
  },
  {
    copy: 'I am eager to respond more fully to the food deliveries yesterday, will do so ASAP. The short, immediate version is: I LOVE the food, knocked off the soup and two entrees very quickly, and am looking forward to the rest that will not be too spicy. My son appreciates my sharing the bounty.',
    citation: 'Suzanne'
  }
];
