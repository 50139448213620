import classNames from 'classnames';
import { FC, ReactNode } from 'react';

export interface IconBarItemProps {
  className?: string;
  icon: ReactNode;
  label: string;
}

export const IconBarItem: FC<IconBarItemProps> = ({ className, icon, label }) => (
  <div
    className={classNames(
      `icon-bar__item flex flex-col sm:flex-row flex-1 items-center justify-center px-3 md:px-6`,
      className
    )}
  >
    {icon}
    <p className="text-sm sm:text-base m-0 mt-2 sm:ml-4 sm:mt-0">{label}</p>
  </div>
);
