import { ButtonAccent, Form, InputText, useSnackbar } from '@lambdacurry/component-library';
import axios from 'axios';
import classNames from 'classnames';
import * as Yup from 'yup';
import { Container } from '../Container/Container';
import { FooterIcon } from './FooterIcon';
import { FacebookIcon, InstagramIcon, LinkedInIcon } from '../../images';
import './footer.scss';

export const Footer = () => {
  const { addSnackbar } = useSnackbar();

  const handleSubmit = async ({ email }) => {
    const response = await axios.post(
      `https://api.hsforms.com/submissions/v3/integration/submit/1788678/71f1dde6-f19d-4d4d-aa85-2282665956aa`,
      {
        submittedAt: new Date().getTime(),
        fields: [
          {
            objectTypeId: '0-1',
            name: 'email',
            value: email
          }
        ],
        context: {
          pageUri: 'https://eat.nibll.com',
          pageName: 'NIBLL - Landing Page'
        },
        legalConsentOptions: {
          consent: {
            consentToProcess: true,
            text: 'I agree to allow NIBLL to store and process my personal data.',
            communications: [
              {
                value: true,
                subscriptionTypeId: 999,
                text: 'I agree to receive marketing communications from NIBLL.'
              }
            ]
          }
        }
      }
    );

    if (response.status !== 200) addSnackbar('Your email did not submit correctly.', { variant: 'error' });
  };

  return (
    <footer className="bg-brand-primary py-3 sm:py-4 md:py-6 lg:py-8 xl:py-20">
      <Container>
        <div className="bg-brand-background flex flex-col items-center md:flex-row justify-between px-3 md:px-4">
          <div className="flex items-center my-3">
            <FooterIcon href="https://www.facebook.com/nibll.eats/" icon={<FacebookIcon className="h-6" />} />
            <FooterIcon href="https://www.instagram.com/nibll.eats/" icon={<InstagramIcon className="h-6" />} />
          </div>

          <Form
            className="flex flex-col md:flex-row items-center justify-between md:justify-end w-full"
            initialValues={{ email: '' }}
            onSubmit={values => handleSubmit(values)}
            validateOnBlur={false}
            validationSchema={Yup.object().shape({
              email: Yup.string().email('Please enter a valid email.').required('This field is required.')
            })}
          >
            {formikProps => {
              return (
                <>
                  {formikProps.submitCount >= 1 && <h6 className="text-center">Thanks for the submission!</h6>}
                  {formikProps.submitCount < 1 && (
                    <>
                      <h6 className="text-center">Subscribe for exclusive offers & more</h6>

                      <InputText
                        className={classNames(`m-0`, { error: formikProps.errors.email })}
                        name="email"
                        placeholder={!formikProps.errors.email ? 'Enter your email' : 'Please enter a valid email'}
                      />

                      <ButtonAccent
                        className="my-3 !text-black w-full md:w-auto"
                        disabled={!!formikProps.errors.email || !formikProps.values.email}
                        type="submit"
                      >
                        Subscribe
                      </ButtonAccent>
                    </>
                  )}
                </>
              );
            }}
          </Form>
        </div>
      </Container>
    </footer>
  );
};
