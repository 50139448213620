import classNames from 'classnames';
import { FC } from 'react';

export interface SplitPanelImageProps {
  alt: string;
  className?: string;
  src: string;
}

export const SplitPanelImage: FC<SplitPanelImageProps> = ({ alt, className, src }) => (
  <div
    className={classNames(`col-span-12 md:col-span-5`, className)}
    style={{ backgroundImage: `url(${src})`, backgroundPosition: 'center', backgroundSize: 'cover' }}
  />
);
