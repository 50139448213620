import classNames from 'classnames';
import { FC } from 'react';
import { GreenDiamond } from '../../images';

export interface ImageColumnProps {
  className?: string;
  copy: string;
  image: { url: string; alt: string };
}

export const ImageColumn: FC<ImageColumnProps> = ({ className, copy, image }) => {
  return (
    <div
      className={classNames(`three-columns__column col-span-12 xs:col-span-4 flex flex-col items-center`, className)}
    >
      <img src={image.url} alt={image.alt} />
      <GreenDiamond className="mt-6 mb-4 max-w-[248px]" />
      <h6 className="capitalize text-center">{copy}</h6>
    </div>
  );
};
