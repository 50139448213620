import classNames from 'classnames';
import { FC } from 'react';

export interface SplitPanelContent {
  className?: string;
}

export const SplitPanelContent: FC<SplitPanelContent> = ({ children, className }) => (
  <div
    className={classNames(
      `split-panel__content col-span-12 md:col-span-7 flex flex-col items-start justify-center`,
      className
    )}
  >
    {children}
  </div>
);
