import classNames from 'classnames';
import { FC } from 'react';
import Countdown from 'react-countdown';
import { nextArrow } from '../../images';
import './custom-countdown.scss';

export interface CustomCountdownProps {
  className?: string;
}

export const CustomCountdown: FC<CustomCountdownProps> = ({ className }) => {
  const renderer = ({ minutes, seconds }) => (
    <div className={classNames(`custom-countdown flex flex-wrap items-center justify-center`, className)}>
      <p className="custom-countdown__divider h6 leading-6 m-0 px-8 lg:px-10 text-center w-[88px] md:w-[116px]">
        {minutes}
        <br />
        <span className="font-body font-normal lowercase text-xs">min</span>
      </p>

      <p className="custom-countdown__divider h6 leading-6 m-0 px-8 lg:px-10 text-center w-[88px] md:w-[116px]">
        {seconds}
        <br />
        <span className="font-body font-normal lowercase text-xs">sec</span>
      </p>

      <p className="custom-countdown__cta m-0 mt-4 sm:mt-0 pl-4 md:pl-6 lg:pl-10 text-center md:text-left text-xs sm:text-sm md:text-base w-full sm:w-[116px] md:w-[168px]">
        save now <img className="inline ml-2 lg:ml-4 max-w-[16px] md:max-w-[24px]" src={nextArrow.default} alt="" />
      </p>
    </div>
  );

  return <Countdown date={Date.now() + 900000} renderer={renderer}></Countdown>;
};
