import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import classNames from 'classnames';
import { useMetadata } from './hooks/useMetadata';
import { MetadataProps } from './Metadata.types';

export const Metadata: FC<MetadataProps> = ({ bodyClassName, children, ...props }) => {
  const {
    lang,
    charSet,
    favicon,
    titleTemplate,
    defaultTitle,
    baseBodyClassName,
    title,
    description,
    author,
    ogImage,
    ogTitle,
    ogDescription,
    twitterSite,
    twitterCreator,
    twitterImage,
    twitterTitle,
    twitterDescription
  } = useMetadata(props);

  return (
    <Helmet htmlAttributes={{ lang }} titleTemplate={titleTemplate} defaultTitle={defaultTitle}>
      {charSet && <meta charSet={charSet} />}

      <title>{title}</title>

      <body className={classNames(baseBodyClassName, bodyClassName)} />

      {/* TODO: Figure out how to get the favicon to override the ones generated by `gatsby-plugin-manifest`. */}
      {favicon && <link rel="shortcut icon" href={favicon} sizes="32x32" />}

      {description && <meta name="description" content={description} />}
      {author && <meta name="author" content={author} />}

      <meta property="og:type" content="website" />
      {ogImage && <meta property="og:image" content={ogImage} />}
      {ogTitle && <meta property="og:title" content={ogTitle} />}
      {ogDescription && <meta property="og:description" content={ogDescription} />}

      <meta name="twitter:card" content="summary" />
      {twitterSite && <meta name="twitter:site" content={twitterSite} />}
      {twitterCreator && <meta name="twitter:creator" content={twitterCreator} />}
      {twitterImage && <meta name="twitter:image" content={twitterImage} />}
      {twitterTitle && <meta name="twitter:title" content={twitterTitle} />}
      {twitterDescription && <meta name="twitter:description" content={twitterDescription} />}

      {children}
    </Helmet>
  );
};
