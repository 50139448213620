import { useRef } from 'react';
import Slider, { Settings } from 'react-slick';
import { companySlides, sportSlides } from './PartnerSlider.helpers';
import { Container, Grid, Section } from '..';
import { nextArrow, prevArrow } from '../../images';
import './partner-slider.scss';

export const PartnerSlider = () => {
  const companySliderRef = useRef(null);
  const sportSliderRef = useRef(null);

  const settings: Settings = {
    arrows: false,
    autoplay: true,
    dots: false,
    infinite: true,
    responsive: [
      {
        breakpoint: 640,
        settings: { slidesToShow: 2 }
      },
      {
        breakpoint: 768,
        settings: { slidesToShow: 3 }
      }
    ],
    slidesToScroll: 1,
    slidesToShow: 4
  };

  const handleClickPrev = current => current.slickPrev();
  const handleClickNext = current => current.slickNext();

  return (
    <Section>
      <Container>
        <Grid>
          <div className="col-span-12">
            <h2>We Feed Top Performers.</h2>
          </div>

          <div className="col-span-4 sm:col-span-3 md:col-span-2">
            <h6>Companies</h6>
            <div className="mt-3">
              <button onClick={() => handleClickPrev(companySliderRef.current)}>
                <img className="max-w-[40px] mr-3" src={prevArrow.default} alt="Click to go to the previous slide." />
              </button>

              <button onClick={() => handleClickNext(companySliderRef.current)}>
                <img className="max-w-[40px] ml-3" src={nextArrow.default} alt="Click to go to the next slide." />
              </button>
            </div>
          </div>

          <Slider
            className="company-slider col-span-8 sm:col-span-9 md:col-span-10"
            ref={companySliderRef}
            {...settings}
          >
            {companySlides.map((slide, index) => (
              <div className="px-6" key={index}>
                <img src={slide.default} alt="" />
              </div>
            ))}
          </Slider>

          <div className="col-span-4 sm:col-span-3 md:col-span-2">
            <h6>Sports</h6>
            <div className="mt-3">
              <button onClick={() => handleClickPrev(sportSliderRef.current)}>
                <img className="max-w-[40px] mr-3" src={prevArrow.default} alt="Click to go to the previous slide." />
              </button>

              <button onClick={() => handleClickNext(sportSliderRef.current)}>
                <img className="max-w-[40px] ml-3" src={nextArrow.default} alt="Click to go to the next slide." />
              </button>
            </div>
          </div>

          <Slider className="sport-slider col-span-8 sm:col-span-9 md:col-span-10" ref={sportSliderRef} {...settings}>
            {sportSlides.map((slide, index) => (
              <div className="px-6" key={index}>
                <img src={slide.default} alt="" />
              </div>
            ))}
          </Slider>
        </Grid>
      </Container>
    </Section>
  );
};
